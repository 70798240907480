import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@intranet/shared/shared.module';
import { OverlayModule } from '@intranet/components/overlay/overlay.module';
import { DialogModule } from '@intranet/components/dialog/dialog.module';
import { TooltipModule } from '@intranet/components/tooltip/tooltip.module';
import { PipesModule } from '@intranet/core/pipes/pipes.module';
import { ActionModule } from '@intranet/modules/actions/action.module';
import { TabsModule } from '@intranet/components/tabs/tabs.module';

import { JobsComponent } from './jobs.component';
import { JobsDetailComponent } from './detail/jobs-detail.component';
import { JobsManageComponent } from './manage/jobs-manage.component';
import { JobsListingComponent } from './listing/jobs-listing.component';
import { JobsTableComponent } from './listing/jobs-table/jobs-table.component';
import { JobsSearchComponent } from './listing/search/jobs-search.component';
import { jobRoutes } from './jobs.routes';
import { JobFinanceInfoComponent } from './detail/finance-info/job-finance-info.component';
import { JobFinanceMarginsComponent } from './detail/job-finance-margins/job-finance-margins.component';
import { MatCurrencyFormatModule } from 'mat-currency-format';
import { Currency } from '../finance';
import { TogglModule } from '../toggl';

@NgModule({
    declarations: [
        JobsDetailComponent,
        JobsManageComponent,
        JobsComponent,
        JobsListingComponent,
        JobsTableComponent,
        JobsSearchComponent,
        JobFinanceInfoComponent,
        JobFinanceMarginsComponent
    ],
    imports: [
        RouterModule.forChild(jobRoutes),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCurrencyFormatModule,
        DialogModule,
        PipesModule,
        ActionModule,
        SharedModule,
        TabsModule,
        OverlayModule,
        TooltipModule,
        TogglModule
    ],
    providers: [CurrencyPipe],
    exports: [JobsManageComponent, JobsListingComponent],
})
export class JobsModule {}
