import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    ActionEdit,
    ActionNote,
    DeleteActionResult,
    GetActionResult,
    InitActionResult,
    JobAction,
    JobActionSearchQuery,
    JobActionType,
    JobActionView,
    RecentActions,
    TimePeriod,
    UpdateActionResult,
} from './action.types';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class ActionService {
    private _jobActions: BehaviorSubject<JobAction[]> = new BehaviorSubject([]);
    private _jobActionsTotalResults: BehaviorSubject<number> =
        new BehaviorSubject(0);
    private _filterQuery: BehaviorSubject<JobActionSearchQuery> =
        new BehaviorSubject(null);
    private _view: BehaviorSubject<JobActionView> = new BehaviorSubject(
        (localStorage.getItem('action.view') as JobActionView) ||
            JobActionView.List
    );

    constructor(
        private _http: HttpClient,
        private _datePipe: DatePipe,
        @Inject('BASE_URL') private originUrl: string
    ) {}

    get jobActions$(): Observable<JobAction[]> {
        return this._jobActions.asObservable();
    }

    set jobActions(value: JobAction[]) {
        this._jobActions.next(value);
    }

    set view(value: JobActionView) {
        localStorage.setItem('action.view', value);
        this._view.next(value);
    }

    get view(): JobActionView {
        return this._view.value;
    }

    get view$(): Observable<JobActionView> {
        return this._view.asObservable();
    }

    get filterQuery$(): Observable<JobActionSearchQuery> {
        return this._filterQuery.asObservable();
    }

    get jobActionsTotalResults$(): Observable<number> {
        return this._jobActionsTotalResults.asObservable();
    }

    get(actionId: number): Observable<GetActionResult> {
        return this._http
            .get<GetActionResult>(`${this.originUrl}api/action/${actionId}`)
            .pipe(map((r) => new GetActionResult(r)));
    }

    getAll(query?: JobActionSearchQuery): Observable<JobAction[]> {
        if (this.view === JobActionView.Board) {
            query.resultsPerPage = -1;
            query.sortBy = 'priority';
            query.sortDir = 'desc';
        }
        return this._http.get<JobAction[]>(this._buildSearchQuery(query)).pipe(
            map((response: any) => {
                if (response.success) {
                    // let jobActions: any = query.isPaginating
                    //     ? this._jobActions.value
                    //     : [];
                    // response.jobActions.forEach((data) =>
                    //     jobActions.push(this._mapJobAction(data))
                    // );

                    // Cache for future use
                    this._jobActions.next(response.jobActions);
                    this._jobActionsTotalResults.next(response.totalResults);

                    return response.jobActions;
                }

                throw new Error(response.error);
            })
        );
    }

    getNotes(jobActionId: number): Observable<ActionNote[]> {
        return this._http
            .get<ActionNote[]>(
                `${this.originUrl}api/action/${jobActionId}/notes`
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response.notes;
                    }

                    throw new Error(response.error);
                })
            );
    }

    create(action: JobAction): Observable<JobAction> {
        let body = JSON.stringify(action);

        return this._http
            .post<UpdateActionResult>(`${this.originUrl}api/action`, body)
            .pipe(
                map((result) => {
                    if (!result.success) {
                        //return Observable.throw(result.errorMessage);
                        throw new Error(result.error);
                    }

                    let currentJobActions = this._jobActions.getValue();
                    currentJobActions.push(result.jobAction);
                    this._jobActions.next(currentJobActions);

                    return result.jobAction;
                })
            );
    }

    update(action: JobAction): Observable<JobAction> {
        return this._http
            .patch<UpdateActionResult>(
                `${this.originUrl}api/action`,
                JSON.stringify(action)
            )
            .pipe(
                map((r) => {
                    const result = new UpdateActionResult(r);
                    if (!result.success) {
                        throw new Error(result.error);
                    }

                    // Update the action locally
                    let currentJobActions = this._jobActions.getValue();
                    currentJobActions = currentJobActions.map((a) => {
                        if (a.jobActionId === result.jobAction.jobActionId) {
                            a = result.jobAction;
                        }

                        return a;
                    });

                    this._jobActions.next(currentJobActions);

                    return result.jobAction;
                })
            );
    }

    reload() {
        this._jobActions.next([...this._jobActions.getValue()]);
    }

    delete(jobActionId: number): Observable<boolean> {
        return this._http
            .delete<DeleteActionResult>(
                `${this.originUrl}api/action/${jobActionId}`
            )
            .pipe(
                map((r) => {
                    const result = new DeleteActionResult(r);
                    if (!result.success) {
                        throw new Error(result.error);
                    }

                    // Remove from cache
                    let currentJobActions = this._jobActions.getValue();
                    currentJobActions = currentJobActions.filter(
                        (a) => a.jobActionId !== jobActionId
                    );

                    this._jobActions.next(currentJobActions);

                    return true;
                })
            );
    }

    getRecentActionsForUser(
        userid: string,
        period: TimePeriod
    ): Observable<RecentActions> {
        return this._http.get<RecentActions>(
            `${this.originUrl}api/action/user/${userid}/${period}`
        );
    }

    getRecentActionsForGroup(
        groupid: number,
        period: TimePeriod
    ): Observable<RecentActions> {
        return this._http.get<RecentActions>(
            `${this.originUrl}api/action/group/${groupid}/${period}`
        );
    }

    getRecentActionsForUserAfterLastUpdate(
        userid: string,
        period: TimePeriod,
        lastupdated: string
    ): Observable<RecentActions> {
        var params = new URLSearchParams();
        params.set('mostrecent', lastupdated);
        return this._http.get<RecentActions>(
            `${
                this.originUrl
            }api/action/user/${userid}/${period}?mostrecent=${encodeURIComponent(
                lastupdated
            )}`
        );
    }

    getRecentActionsForGroupAfterLastUpdate(
        groupid: number,
        period: TimePeriod,
        lastupdated: string
    ): Observable<RecentActions> {
        var params = new URLSearchParams();
        params.set('mostrecent', lastupdated);
        return this._http.get<RecentActions>(
            `${
                this.originUrl
            }api/action/group/${groupid}?mostrecent=${encodeURIComponent(
                lastupdated
            )}`
        );
    }

    submitNoteForAction(note: ActionNote): Observable<ActionNote[]> {
        let body = JSON.stringify(note);

        return this._http
            .post<ActionNote[]>(
                `${this.originUrl}api/action/${note.jobActionId}/notes`,
                body
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response.notes;
                    }

                    throw new Error(response.error);
                })
            );
    }

    deleteNoteForAction(jobActionId: number, noteId: number) {
        return this._http
            .delete<ActionNote[]>(
                `${this.originUrl}api/action/${jobActionId}/notes/${noteId}`
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response.notes;
                    }

                    throw new Error(response.error);
                })
            );
    }

    editNoteForAction(jobActionId: number, noteId: number, text: string) {
        let body = JSON.stringify({ text });

        return this._http
            .put<ActionNote[]>(
                `${this.originUrl}api/action/${jobActionId}/notes/${noteId}`,
                body
            )
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response.notes;
                    }

                    throw new Error(response.error);
                })
            );
    }

    actionFieldStartEdit(
        jobActionId: number,
        userId: string,
        shortName: string,
        propertyName: string
    ) {
        let body = JSON.stringify({
            userid: userId,
            name: shortName,
            prop: propertyName,
            jobActionId: jobActionId,
        });

        this._http
            .post<ActionEdit[]>(`${this.originUrl}api/action/StartEdit`, body)
            .subscribe((r) => {
                console.log(r);
            });
    }

    actionFieldStopEdit(
        jobActionId: number,
        userId: string,
        shortName: string,
        propertyName: string
    ) {
        let body = JSON.stringify({
            userid: userId,
            name: shortName,
            prop: propertyName,
            jobActionId: jobActionId,
        });

        this._http
            .post<ActionEdit[]>(`${this.originUrl}api/action/StopEdit`, body)
            .subscribe((r) => {
                console.log(r);
            });
    }

    initRecurAction(jobId: number): Observable<InitActionResult> {
        let body = JSON.stringify({
            jobId: jobId,
            parentActionId: 0,
        });

        return this._http
            .post<InitActionResult>(
                `${this.originUrl}api/action/InitRecurAction`,
                body
            )
            .pipe(map((r) => new InitActionResult(r)));
    }

    reset(): void {
        this._jobActions.next([]);
        this._jobActionsTotalResults.next(0);
    }

    clearFilterQuery(): void {
        this._filterQuery.next(null);
    }

    private _buildSearchQuery(searchQuery?: JobActionSearchQuery): string {
        let baseUrl = `${this.originUrl}api/action`;
        if (!searchQuery) return `${baseUrl}?page=1`;

        let query = `?page=${searchQuery.page}`;

        if (searchQuery.clientId) {
            query += `&clientId=${searchQuery.clientId}`;
        }

        if (searchQuery.jobId) {
            query += `&jobId=${searchQuery.jobId}`;
        }

        if (searchQuery.owner) {
            query += `&owner=${searchQuery.owner}`;
        }

        if (searchQuery.type) {
            query += `&type=${searchQuery.type}`;
        }

        if (searchQuery.searchTerm) {
            query += `&searchTerm=${encodeURIComponent(
                searchQuery.searchTerm
            )}`;
        }

        if (searchQuery.sortBy) {
            let direction = searchQuery.sortDir || 'asc';
            query += `&sortBy=${searchQuery.sortBy}&sortDir=${direction}`;
        }

        if (searchQuery.status) {
            query += `&status=${searchQuery.status}`;
        }

        if (searchQuery.timePeriod) {
            query += `&timePeriod=${searchQuery.timePeriod}`;
        }

        if (searchQuery.resultsPerPage) {
            query += `&resultsPerPage=${searchQuery.resultsPerPage}`;
        }

        //if (!_.isEqual(this._filterQuery.value, searchQuery)) {
        this._filterQuery.next(searchQuery);
        //}

        return `${baseUrl}${query}`;
    }
}
