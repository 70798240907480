import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
    FilteredJobListResult,
    Job,
    JobDetailWithActionStatuses,
    JobEditDetails,
    JobSearchQuery,
    JobType,
    JobUpdateResult,
} from './job.types';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { TogglService } from '../toggl';

@Injectable({
    providedIn: 'root',
})
export class JobService {
    private _jobs: BehaviorSubject<Job[]> = new BehaviorSubject([]);
    private _jobsTotalResults: BehaviorSubject<number> = new BehaviorSubject(0);
    private _jobTypes: BehaviorSubject<JobType[]> = new BehaviorSubject([]);
    private _filterQuery: BehaviorSubject<JobSearchQuery> = new BehaviorSubject(
        null
    );

    constructor(
        private _http: HttpClient,
        private togglService: TogglService,
        @Inject('BASE_URL') private originUrl: string
    ) {}

    get jobs$(): Observable<Job[]> {
        return this._jobs.asObservable();
    }

    set jobs(value: Job[]) {
        this._jobs.next(value);
    }

    get jobTypes$(): Observable<JobType[]> {
        return this._jobTypes.asObservable();
    }

    get filterQuery$(): Observable<JobSearchQuery> {
        return this._filterQuery.asObservable();
    }

    get filterQuery(): JobSearchQuery {
        return this._filterQuery.value;
    }

    get jobsTotalResults$(): Observable<number> {
        return this._jobsTotalResults.asObservable();
    }

    getJobs(query?: JobSearchQuery): Observable<Job[]> {
        return this._http.get<Job[]>(this._buildSearchQuery(query)).pipe(
            map((response: any) => {
                if (response.success) {
                    // let jobs: any = query.isPaginating ? this._jobs.value : [];
                    // response.jobs.forEach((data) =>
                    //     jobs.push(this._mapJob(data))
                    // );

                    // Cache for future use
                    this._jobs.next(response.jobs);
                    this._jobsTotalResults.next(response.totalResults);

                    return response.jobs;
                }

                throw new Error(response.error);
            })
        );
    }

    getJob(jobId: number): Observable<Job> {
        return this._http.get<Job>(`${this.originUrl}api/Job/GetJob/${jobId}`);
    }

    getJobByJobNumber(jobNumber: number): Observable<Job> {
        return this._http.get<Job>(
            `${this.originUrl}api/Job/GetJobByJobNumber/${jobNumber}`
        );
    }

    getJobDetails(jobId: number): Observable<JobDetailWithActionStatuses> {
        return this._http.get<JobDetailWithActionStatuses>(
            `${this.originUrl}api/Job/GetJobDetails/${jobId}`
        );
    }

    getJobTypes(): Observable<JobType[]> {
        return this._http
            .get<JobType[]>(`${this.originUrl}api/Job/GetJobTypes`)
            .pipe(tap((jobTypes) => this._jobTypes.next(jobTypes)));
    }

    addOrUpdateJob(job: Job): Observable<JobUpdateResult> {
        let jobToUpdate = job;

        if (job.jobId) {
            jobToUpdate = new Job();
            jobToUpdate.active = job.active;
            jobToUpdate.archive = job.archive;
            jobToUpdate.clientId = job.clientId;
            jobToUpdate.dateCreated = job.dateCreated;
            jobToUpdate.deliveryDate = job.deliveryDate;
            jobToUpdate.jobActions = [];
            jobToUpdate.jobId = job.jobId;
            jobToUpdate.jobNoOR = job.jobNoOR;
            jobToUpdate.jobOwnerId = job.jobOwnerId;
            jobToUpdate.jobStatus = job.jobStatus;
            jobToUpdate.jobTitle = job.jobTitle;
            jobToUpdate.jobTypeId = job.jobTypeId;
        }

        return this._http
            .post(`${this.originUrl}api/Job/AddOrUpdateJob`, jobToUpdate)
            .pipe(map((r) => r as JobUpdateResult));
    }

    archive(jobId: number, archive: boolean): Observable<JobUpdateResult> {
        return this._http
            .post(`${this.originUrl}api/Job/Archive`, { jobId, archive })
            .pipe(map((r) => r as JobUpdateResult));
    }

    reset(): void {
        this._jobs.next([]);
        this._jobsTotalResults.next(0);
    }

    private _buildSearchQuery(searchQuery?: JobSearchQuery): string {
        var baseUrl = `${this.originUrl}api/job`;
        if (!searchQuery) return baseUrl;

        var query = `?page=${searchQuery.page}`;

        if (searchQuery.clientId) {
            query += `&clients=${searchQuery.clientId}`;
        } else {
            if (searchQuery.clients && searchQuery.clients.length > 0) {
                searchQuery.clients.forEach(
                    (client) => (query += `&clients=${client}`)
                );
            }
        }

        if (searchQuery.userId) {
            query += `&userId=${searchQuery.userId}`;
        }

        if (searchQuery.searchTerm) {
            query += `&searchTerm=${encodeURIComponent(
                searchQuery.searchTerm
            )}`;
        }

        if (searchQuery.sortBy) {
            let direction = searchQuery.sortDir || 'asc';
            query += `&sortBy=${searchQuery.sortBy}&sortDir=${direction}`;
        }

        if (searchQuery.archived) {
            query += `&archived=true`;
        }

        if (searchQuery.status) {
            query += `&status=${searchQuery.status}`;
        }

        if (searchQuery.resultsPerPage) {
            query += `&resultsPerPage=${searchQuery.resultsPerPage}`;
        }

        if (!_.isEqual(this._filterQuery.value, searchQuery)) {
            this._filterQuery.next(searchQuery);
        }

        return `${baseUrl}${query}`;
    }

    private _mapJob(job: any): Job {
        let result: Job = job;
        return result;
    }
}
